@import "../../../assets/fonts";
@import "../../UI/media";

.Logo{
    cursor: pointer;
    display: inline-block;
    margin-left: 5%;
    height: 100%;
    & > *{
        display: inline-block;
    }
    img{
        height: 90px;
        margin-top: 5px;
    }
}


@include mobile{
    .Logo{
        margin: 0;
        width: 100%;
        text-align: center;
        >img{
            float: left;
        }
    }
}
