@import "../../UI/media";

.Toolbar{
    height: 100px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999999;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    &:after{
        content:"";
        width: 100%;
        border-bottom: 1px solid black;
        display: block;
    }
    img{
        height: 100%;
    }
}

.Nav{
    display: inline-block;
    float:right;
    height: 100%;
}

.Hamburger{
    display: inline-block;
    position: absolute;
    right: 0;
    padding: 23px;
}

@include mobile{
    .Nav{
        display: none;
    }
}
@include notMobile{
    .Hamburger{
        display: none;
    }
}
