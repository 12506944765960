@import '../../../../assets/fonts';
@import "../../../UI/media";

.NavItem{
  display: flex;
  margin: 0;
  width: auto;
  align-items: center;
  a,div{
    padding: 15px;
    height: 100%;
    @extend .mainMedium;
    color: $mainGrey;
    text-decoration: none;
  }
  &:hover{
    background-color: $mainGrey;
    a,div{color: white;}
  }
}

@include mobile{
  .NavItem{
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid grey;
    padding: 15px 0;

    a{
      width: 100%;
      text-align: center;
    }
  }
}

