@import "../components/UI/media";
.Layout{
  position: relative;
  min-height: 100vh;

}
@include mobile{
  .Layout{
    min-height: 100%;
  }
  html, body {
    height: 100%;
  }
}
