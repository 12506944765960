@import "../../../assets/fonts";
@import "../../UI/media";



#LeaveReview{
  height:150px;
  background-color: white;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  > * {
    display: inline-block;
  }
  h1{
    @extend .mainExtraLight;
    margin: 0;
  }
  button{
    color: white;
    background-color: $mainGrey;
    width: 140px;
    height: 50px;
    border:none;
    font-size: larger;
    @extend  .mainSemiBold;
    &:hover{
      background-color: black;
      cursor: pointer;
    }

  }
}

@include mobile{
  #LeaveReview{
    height: 100px;
    h1{
      font-size: 20px;
      margin: 10px 0;
    }
    button{
      width: 100px;
      height: 40px;
      font-size: small;
    }
  }
}
