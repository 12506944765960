@import "../../../assets/fonts";
@import "../../../components/UI/media";
.Content{
  width: 70%;
  margin: auto;
  text-align: center;
  @include LessThan(1400px){
    width: 80%;
  }
  p{
    font-size: 22px;
    @extend .mainMedium;
  }
  &Div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    p{
      font-size: 17px;
     @extend .mainExtraLight;
    };
  }
  &Wood{
    margin-top: 20px;
  }
}
