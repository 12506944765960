@import "../../../assets/fonts";
@import "../media";

#ScrollDownArrow{
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  @include between(429px,623px){
    height: 50px;
  }
  img{
    position: relative;
    top: 25%;
    @include between(429px,623px){
      top:5%;
    }
  }
  &:hover{
    background-color: $transBlack;
  }
}
