@import "../../../../assets/fonts";
@import "../../../UI/media";


.ReviewItem{
  @extend .mainExtraLight;
  width: 330px;
  height: 400px;
  background-color: white;
  display: inline-block;
  padding: 15px;
  margin: 0 10px 0 10px;
  text-align: left;

  position: relative;

  #date{
    color:grey;
    margin-top: 10px;
    position: absolute;
    right: 10px;
  }
  #ReviewText{
    height: 50%;
    overflow: hidden;
    width: 60%;
    display: inline-block;
    word-wrap: break-word;
    padding-top: 20px;
    &:hover{
      overflow: auto;
    }
  }
  #CustomerPics{
    display: inline-block;
    width: 37%;
    position: absolute;
    bottom: 35px;
    >div:nth-child(2){
      margin-right: 5px;
    }
  }
  #FirstPic{
    width: 100%;
    height: 100px;
  }
  .OtherTwoPics{
    display: inline-block;
    margin-top: 5px;
    width: 48%;
    height: 75px;
  }
  .ReviewPics{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .2s;
    &:hover{
      transform: scale(1.5);
    }
  }
  .star-ratings{
    display: block;
  }
  &Button{
    position: absolute;
    bottom: 30px;
    left: 50px;
    padding: 20px 40px;
    background-color: black;
    color: white;
    border:none;
    &:hover{
      background-color: grey;
      color: black;
    }
  }
}

.UserInfo{
  height: 65px;
  margin-bottom: 15px;
  max-width: 75%;
  display: block;
  img{
    display: inline-block !important;
  }
  strong{
    margin-right: 5px;
  }
  #avatar{
    float: left;
    margin-right: 5px;
    width: 64px;
    height: 64px;
    border-radius: 50%;

  }
  #smallInfo{
    margin-top: 10px;
    display: inline-block;
  }
}

.stopScrolling{
  height: 100%;
  overflow: hidden;
}

@include between(500px,842px){
  .ReviewItem{
    width: 80%;
  }
  #FirstPic{
    width: 98%;
  }
}
@include LessThan(500px){
  .ReviewItem{
    width: 260px;
    height: 333px;
    #ReviewText{
      padding: 0;
    }
    .OtherTwoPics{
      width: 47%;
      height: 55px;
    }
  }
  .UserInfo{
    max-width: 100%;
    #smallInfo{
      margin-top: 35px;
    }
  }
  #FirstPic{
    width: 98%;
  }
}
