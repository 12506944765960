@import "../../../../assets/fonts";
@import "../../../../components/UI/media";
.NewTechWood{
  margin: auto;
  height: 200px;
  border: 1px solid;
  position: relative;
  color:white;
  @include phoneSmall{
    height: 150px;
  }
  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: -1;
    filter:brightness(0.3);
  }
  h2{
    font-size: 33px;
    @extend .mainExtraLight;
    @include phoneSmall{
      font-size: 23px;
    }
  }
  &:hover{
    img{
      filter:brightness(0.8);
    }
    .btn{
      box-shadow: 1px 1px 25px 10px rgb(107, 105, 110);
      &:hover:before{
        left: 100%;
      }
    }
   }
}
.btn {
  font-size: 1.5rem;
  padding: 1rem 3rem;
  color: white;
  background-color: transparent;
  text-decoration: none;
  border: 1px solid rgb(255, 243, 244);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @include phoneSmall{
    font-size: 1rem;
  }
}
.btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
                  120deg,
                  transparent,
                  rgb(98, 98, 98),
                  transparent
  );
  transition: all 650ms;
}

