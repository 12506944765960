@import "../../../assets/fonts";
@import "../../UI/media";

.Info{
  height: 475px;
  background-color: $mainCherry;

  h1{
    @extend .mainSemiBold;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    position: absolute;
    font-size: 3em;
    margin-left: -2px;
    @include phoneSmall{
      font-size: 2em;
    }
    span{
      background-image: linear-gradient(90deg, #020024 0%, black 51.5%, white 49%);
      -webkit-background-clip: text;
      color: transparent;
    }

  }
  &Image{
    width: 50%;
    height: 100%;
    display: inline-block;
    img{
      width: 100%;
      height: 475px;
      object-fit: cover;
    }
  }
  &Text{
    width: 30%;
    max-height: 310px;
    display: inline-block;
    position: absolute;
    padding-top: 164px;
    padding-left: 10%;
    font-size: 22px;
    color: white;
    @extend .mainExtraLight;
    @include phoneSmall{
      width: 40%;
      font-size: 13px;
      padding-left: 5%;
      padding-top: 95px;
    }
    @include phoneMedium{
      width: 45%;
      font-size: 15px;
      padding-left: 5%;
    }
    @include phoneLarge{
      width: 40%;
      padding-left: 5%;
      font-size: 16px;
    }
    @include between(768px,1144px){
      width: 45%;
      padding-left: 5%;
    }
  }
}

