@import "../../../assets/fonts";
@import "../../UI/media";

#Footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  h1{
    text-align: center;
    @extend .mainSemiBold;
    font-size: xx-large;
  }
  .FooterH3{
    color:white;
    @extend .mainMedium;
    font-size: x-large;
    margin: 0 0 5px;
    @include mobile{
      color: black;
    }
  }

  #mainFooter{
    background-color: $mainCherry;
    padding: 1% 5%;
    display: grid;
    grid-template-columns: 30% 50% auto;
    grid-template-rows: 55% auto;
    &Quote{
      grid-column:1/2;
      grid-row: 1/3;
      padding: 10px 10px 0;
    }
    &Location{
      grid-column:2/3;
      grid-row: 1/2;
      padding: 10px;
    }
    &Socials{
      grid-column:3/4;
      grid-row: 1/2;
      padding-top: 10px;
    }
    &Touch{
      grid-column:2/4;
      grid-row: 2/3;
      padding: 10px 0 10px 10px;
    }
    @include LessThan(1222px){
      padding: 1% 1%;
    }
  }
}

@include LessThan(767px){
  #Footer {
    h1{
      margin: 5px;
    }
    #mainFooter {
      height: 500px;
      grid-template-columns: 100%;
      grid-template-rows: 50% 15% auto;
      padding: 0;

      &Quote {
        grid-column: 1;
        grid-row: 2/3;
        padding: 0;
      }

      &Location {
        grid-column: 1;
        grid-row: 1/2;
        padding: 0;
      }

      &Socials {
        display: none;
      }

      &Touch {
        grid-column: 1;
        grid-row: 3;
        padding: 0;
      }
    }
  }
  .QuoteButton{
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
    &>button{
      display: block;
      font-size: 25px;
      border-radius: 0;
      border: none;
      padding: 15px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: white;
    }
  }
}
