@import "../../../UI/media";

.GetInTouch{
  height: calc(100% - 36px);
  width: 100%;
  @include LessThan(767px){
    height: 100%;
  }
  &>div{
    width: 50%;
    display: inline-block;
    text-align: center;
    height: 100%;
    vertical-align: top;


  }
  &Content{
       width: 100%;
       margin: auto;
       text-align: center;
      @include LessThan(464px){
        height: 50%;
      }
    &>span{
    display: inline-block;
    margin-left: 5px;
      @include LessThan(518px){
        display: block;
        margin: 0;
      }
    }
  }
  &>div:first-child{
    background-color: white;
    &>div:first-child{
      margin-top: 40px;
      @include LessThan(518px){
        margin-top: 20px;
      }
      @include LessThan(464px){
        margin-top: 0;
        border-bottom: 1px solid black;
      }
    }
  }
  &>div:last-child{
    background-color: #E5E5E5;
    &>div:first-child{
      margin-top: 40px;
      @include LessThan(518px){
        margin-top: 20px;
      }
      @include LessThan(464px){
        margin-top: 0;
        border-bottom: 1px solid black;
      }
    }
  }
}
.link{
  text-decoration: none;
  color:black;
  position: relative;
  bottom: 10px;
  @include LessThan(518px){
    bottom: 0;
  }
  @include LessThan(464px){
    display: inline-block;
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
  }
  >span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
