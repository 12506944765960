@import "../../../assets/fonts";
.Service{
  .Header{
    height: 400px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h1{
      margin: 0;
      padding: 10px 0;
      font-size: 60px;
      position: absolute;
      top: 150px;
      width: 100%;
      text-align: center;
      color: white;
      background-color: #00000080;
      @extend .mainExtraLight;
    }
  }
  .Content{
    width: 80%;
    margin: auto;
    text-align: center;
    white-space: pre-line;
    p{
      font-size: 22px;
      @extend .mainExtraLight;
    }
  }
}
