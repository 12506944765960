@import "../../components/UI/media";
.Review{
  background-color: #4f1f22;
  color: white;
  padding: 50px 10%;
  position: relative;
  .UserData{
    h3{
      margin: 0;
      display: inline-block;
      position: absolute;
      font-size: 25px;
    }
    #avatar{
      display: inline-block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-right: 20px;
    }
    span{
      float:right;
    }
    .star-ratings{
      bottom:40px
    }
    @include mobile{
      text-align: center;
      position: relative;
      span{
        float:none;
        position: absolute;
        left: 50%;
        bottom:0;
      }
    }
    @include LessThan(430px){
      h3{
        display: block;
        position: initial;
      }
      #avatar{
        display: block;
        margin: auto;
      }
      span{
        position: initial;
        display: block;
      }
      .star-ratings{
        bottom: 0;
        display: block;
      }

    }
  }
  .ReviewData{
    text-align: center;
    p{
      padding: 30px 0;
      background-color: #707070;
    }
    h1{
      margin: 0;
    }
  }
}
