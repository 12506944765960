@import "../../../../assets/fonts";
@import "../../../../components/UI/media";
.ServiceItem{
  position: relative;
  padding: 20px;
  flex: 0 0 29%;
  @include between(768px,1150px){
    flex: 0 0 40%;
  }
  @include LessThan(768px){
    flex: 0 0 100%;
  }
  img{
    width: 100%;
    height: 230px;
    object-fit: cover;
  }
  p{
    margin-bottom: 80px;
    @extend .mainExtraLight;
  }
  button{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    border: none;
    background-color: black;
    color: white;
    font-size: 20px;
    padding: 20px;
    width: 90%;
    cursor: pointer;
    &:hover{
      background-color: grey;
    }
  }
}
