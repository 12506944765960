.mainBold{
  font-family: source-sans-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.mainBoldItalic{
  font-family: source-sans-pro, sans-serif;
  font-weight: 700;
  font-style: italic;
}
.mainLight{
  font-family: source-sans-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
}
.mainLightItalic{
  font-family: source-sans-pro, sans-serif;
  font-weight: 300;
  font-style: italic;
}

.mainMedium{
  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.mainSemiBold{
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.mainRegular{
  font-family: source-sans-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.mainExtraLight{
  font-family: source-sans-pro, sans-serif;
  font-weight: 200;
  font-style: normal;
}
.SourceSansProBlack{
  font-family: source-sans-pro,sans-serif;
  font-weight: 900;
  font-style: normal;
}

$mainGrey: #707070;
$transBlack: #00000080;
$mainCherry: #4f1f22;
