@import "../../UI/media";

.NavItems{
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
}
@include mobile{
  .NavItems{
    flex-flow: column;
  }
}
